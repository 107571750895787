:root {
  --bg-color: #361d2e;
  --col-color: #a4c2a8;
  --col-text-color: #2a2b2e;
}

.copywrite-footer {
  color: white;
  font-size: clamp(8px, 3vw, 12px);
  font-family: sans-serif;
  align-items: center;
  text-align: center;
}
