:root {
    /*   --bg-color: #361D2E;*/
       --bg-color: #fef6e0;
       --col-color: #ffb41f;
       /*--col-color: #fef6e0; */

       --col-text-color:#2A2B2E;
       --navbar-text-color: #ffb41f;
       --navbar-hover-color: #F1DEDE;
       --color-gray: #2A2B2E;
       --primary: magenta;
     }
   
.body {
    background-color: var(--bg-color);
    margin: 8px;
}