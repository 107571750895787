@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.root {
  --bg-color: #361d2e;
  --navbar-text-color: #fff;
  --navbar-hover-color: #b784b7;
  --color-gray: #2a2b2e;
}

.navbar {
  display: flex;
  flex-direction: row;
justify-content: center;
  align-items: center;
  font-size: clamp(10px, 3vw, 22px);
  top: 0;
}

/*This is the one that holds the Logo and About.*/
.navbar-container {
  display: grid;
  grid-template-columns: calc(45vw + 1em) calc(45vw + 1em);
  justify-content: space-between;
  align-items: center;
  max-width: 1500px;
  margin-left: calc(1em + 2px);
}

.navbar-logo {
  color: var(--navbar-text-color);
  justify-self: normal;
  cursor: pointer;
  text-decoration: none;
  font-size: clamp(14px, 4vw, 36px);
  font-family: "Bebas Neue";
  align-items: center;
  color: white;
}
.navbar-logo:hover {
  color: #b784b7;
  transition: all 0.2s ease-out;
}

.navbar-logo-emoji {
  color: var(--navbar-text-color);
  justify-self: normal;
  margin-left: 0px;
  cursor: default;
  text-decoration: none;
  font-size: clamp(16px, 3vw, 30px);
  align-items: center;
  margin-bottom: 1rem;
}

.fa-typo3 {
  margin-left: 2.5rem;
  font-size: clamp(16px, 3vw, 30px);
}

.nav-menu {
  display: grid;
  justify-self: normal;
  grid-template-columns: repeat(2, auto);
  grid-gap: calc(2vw + 5px);
  font-size: clamp(14px, 4vw, 36px);
  font-family: "Bebas Neue";
  color: white;
  list-style: none;
  text-align: center;
  justify-content: end;
}

.nav-item {
  height: 2vh;
}
.nav-item:hover {
  border-bottom: 2px solid var(--navbar-hover-color);
  transition: all 0.2s ease-out;
}

.nav-links {
  color: var(--navbar-text-color);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.2rem 0.05rem;
  height: 100%;
}

.nav-links:hover {
  color: #b784b7;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
