@import '../index.css';


/* Apply a global reset to remove default margin and padding */
body, h1, h2, p {
    margin: 0;
    padding: 0;
    color: white
  }
  
  /* Define a container for the About page content */
  .about-container {
    max-width: 800px; /* Adjust as needed */
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Style the main heading */
  .about-heading {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: var(white);
  }
  
  /* Style the introduction section */
  .about-intro {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: var(--col-color); /* Adjust the color to match your design */
  }
  
  /* Style the timeline section */
  .about-timeline {
    margin-top: 40px;
  }
  
  /* Style each timeline entry */
  .timeline-entry {
    position: relative;
    margin-bottom: 30px;
  }
  
  /* Style the timeline dot */
  .timeline-dot {
    width: 16px;
    height: 16px;
    background-color: #ff9900; /* Adjust the color to match your design */
    border-radius: 50%;
    position: absolute;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  /* Style the timeline date */
  .timeline-date {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Style the timeline event description */
  .timeline-description {
    font-size: 14px;
    line-height: 1.4;
    color: #777; /* Adjust the color to match your design */
  }
  
  /* Style the skills and expertise section */
  .about-skills {
    margin-top: 40px;
  }
  
  /* Style individual skill items */
  .skill-item {
    margin-bottom: 10px;
  }
  
  /* Style the testimonials section */
  .about-testimonials {
    margin-top: 40px;
  }
  
  /* Style individual testimonial items */
  .testimonial-item {
    margin-bottom: 30px;
  }
  
  /* Style the contact section */
  .about-contact {
    margin-top: 40px;
  }
  
  /* Style the contact form */
  .contact-form {
    background-color: #f5f5f5; /* Adjust the background color to match your design */
    padding: 20px;
    border-radius: 5px;
  }
  
  /* Style form fields and labels */
  .form-label {
    font-weight: bold;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd; /* Adjust the border color to match your design */
    border-radius: 5px;
  }
  
  /* Style the submit button */
  .submit-button {
    background-color: #ff9900; /* Adjust the background color to match your design */
    color: #fff; /* Adjust the text color to match your design */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #ff6600; /* Adjust the hover color to match your design */
  }