@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap");

.root {
  /*   --bg-color: #361D2E;*/
  --bg-color: #e493b3;
  --col-color: #82093a;
  --col-text-color: #2a2b2e;
}

.flexbox-container {
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: var(--col-color);
}

.container {
  justify-content: center;
  align-items: center;
}

.grid-container {
  display: grid;
  justify-content: center;
  grid-template-columns: calc(45vw + 1em) calc(45vw + 1em);
  grid-template-rows: auto auto;
  column-gap: 0px;
  row-gap: 10px;
}

.grid-container > div {
  padding: calc(0.1vw + 0.1em);
}

.flexbox-item {
  object-fit: cover;
  object-position: center;
  padding: calc(0.5vw + 0.5em);
  border-color: var(--col-color);
  color: #b784b7;
  font-family: "Montserrat", sans-serif;
  font-size: clamp(14px, 2vh, 28px);
}
.flexbox-text-item {
  width: 50vw;
  border: 3px solid black;
  background-color: magenta;
}
